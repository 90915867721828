import { ContentPostContentLocationDownloadDto, FileEntriesDownloadDto, FileEntryDownloadDto, PostDownloadDto, ProjectGroupDownloadDto } from 'collaboration-service';
import { belowBreakpointOrEqual, Button, fadeColor, Flag, getColor, Icon, ISO_3166, lightenColor, LoaderInline, RFC_4646, styled, Theme, Tooltip, useThemePart } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { CellProps, Column, Row, SortingRule, TableInstance, useColumnOrder, useRowSelect, useSortBy, useTable } from 'react-table';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { KeyOfType, shallowCompare } from 'services/Helpers';
import { gotoNamedRoute, gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { getCMSContent, getTranslated, getTranslatedStandardDate, shortenBytes } from 'services/Helpers/TranslationHelpers';
import { ImgI18N, useImgI18N } from 'services/ImgI18N';
import { useAppTheme } from 'services/useAppTheme';
import { ThemeContext } from 'styled-components';
import CMSDirectoryMenu from './CMSDirectoryMenu';
import CMSFileEntryAppIcon from './CMSFileEntryAppIcon';
import CMSFileMenu from './CMSFileMenu';
import { Container } from './CMSFileVersionsInfo';
import { CMSSC as T } from './CMSSC';
import CMSTableMenu from './CMSTableMenu';
import { CMSTheme } from './CMSTheme';
import { cmsFindIndexPathAndFileInCurrentFiles } from './Helpers/CMSHelpers';

const mapper = (state: ApplicationState) => ({
    routeParams: state.params,
    currentProject: state.cmsState.currentProject,
    currentFiles: state.cmsState.currentFiles,
    location: state.cmsState.location,
    locations: state.cmsState.locations,
    contentLanguage: state.contentLanguage,
    filePosts: state.cmsState.filePosts,
    actionsDisabled: state.cmsState.directoryAndFileActionsDisabled,
});
const BC = styled.div`
    transform: translateX(-10px);
    width: 40px;
    height: 40px;
    button>div{margin:0;}
`;

const stopEvent = (ev: any) => {
    ev.stopPropagation();
}

export type CMSFilesGridEventType =
    | { type: "fileLinkCopied", copied: boolean, id: string }
    | { type: "directoryLinkCopied", copied: boolean, path: string }
    ;

interface CMSFilesGridProps {
    hiddenColumns: string[];
    showPreview: (file: FileEntryDownloadDto) => void;
    showArchivedFiles?: boolean;
    info?: (info: string) => void;
    filter?: string;
    onEvent?: (type: CMSFilesGridEventType) => void;
}

export type CMSColumnType = Column<FileEntriesDownloadDto | FileEntryDownloadDto>;
export type CMSRowType = Row<FileEntriesDownloadDto | FileEntryDownloadDto>;
export type CMSCellPropsType = CellProps<FileEntriesDownloadDto | FileEntryDownloadDto>;
export type CMSTableInstanceType = TableInstance<FileEntriesDownloadDto | FileEntryDownloadDto>;
export type CMSHeaderProp = TableInstance<FileEntriesDownloadDto | FileEntryDownloadDto> & { column: CMSColumnType };

export const getCurrentDirectory = (currentProject: ProjectGroupDownloadDto | undefined, path: string[]) => {
    let toRet = currentProject?.files;
    _.forEach(path, p => {
        toRet = _.find(toRet?.directories, d => d.name === p) as FileEntriesDownloadDto;
    });
    return toRet;
};

export const getCurrentDirFiles = (cur: FileEntriesDownloadDto | undefined, currentFiles: FileEntryDownloadDto[] | undefined, withDirectories: boolean, transformedNames?: { location?: ContentPostContentLocationDownloadDto, language: string }) => {
};

const sortByDate = (rowA: CMSRowType, rowB: CMSRowType, columnId: string, desc?: boolean) => {
    const vA = (rowA.original as any)[columnId] as string;
    const vB = (rowB.original as any)[columnId] as string;
    const dA = vA ? new Date(vA) : new Date("01.01.1900");
    const dB = vB ? new Date(vB) : new Date("01.01.1900");
    return dA < dB ? -1 : 1;
}

const stateValue = (fe: FileEntryDownloadDto) => {
    if (fe.locked)
        return 2;
    if (fe.isArchived)
        return 1;
    if (fe.isProjectOutput)
        return 0;
    return fe.contents ? 3 : 4;
}

const sortByVersion = (rowA: CMSRowType, rowB: CMSRowType, columnId: string, desc?: boolean) => {
    const vAStr = (rowA.original as FileEntryDownloadDto).version;
    const vBStr = (rowB.original as FileEntryDownloadDto).version;
    const vA = vAStr !== "" ? parseInt(vAStr) : 0;
    const vB = vBStr !== "" ? parseInt(vBStr) : 0;
    return vA < vB ? -1 : 1;
}

const sortByState = (rowA: CMSRowType, rowB: CMSRowType, columnId: string, desc?: boolean) => {
    const vA = stateValue(rowA.original as FileEntryDownloadDto);
    const vB = stateValue(rowB.original as FileEntryDownloadDto);
    return vA < vB ? -1 : 1;
}

const sortFiles = (rowA: CMSRowType, rowB: CMSRowType, columnId: string, desc?: boolean) => {
    const fA = (rowA.original as any)?.contents !== undefined ? "z" : "a";
    const fB = (rowB.original as any)?.contents !== undefined ? "z" : "a";
    const vA = fA + ((rowA.original as any)[columnId] as string).toLowerCase();
    const vB = fB + ((rowB.original as any)[columnId] as string).toLowerCase();
    return vA < vB ? -1 : 1;
};

const sortBySize = (rowA: CMSRowType, rowB: CMSRowType, columnId: string, desc?: boolean) => {
    const vA = (rowA.original as any)?.size as number ?? 0;
    const vB = (rowB.original as any)?.size as number ?? 0;
    return vA < vB ? -1 : 1;
};

const sortByStringValue = (what: KeyOfType<FileEntryDownloadDto, string>) => (rowA: CMSRowType, rowB: CMSRowType, columnId: string, desc?: boolean) => {
    const vA = (rowA.original as FileEntryDownloadDto)[what];
    const vB = (rowB.original as FileEntryDownloadDto)[what];
    return vA < vB ? -1 : 1;
}

export const setFileEntryName = (
    fileEntry: FileEntryDownloadDto,
    currentProject: ProjectGroupDownloadDto | undefined,
    language: string,
    location: ContentPostContentLocationDownloadDto | undefined,
    filePosts: {
        [key: string]: PostDownloadDto[]
    }) => {
    const newFile = _.clone(fileEntry);
    if (location) {
        const c = getCMSContent({ fileEntry: newFile, language, location, filePosts });
        if (c.media) {
            newFile.name = c.media.fileName;
            (newFile as any).size = c?.media?.size;
        }
        if (c.text) {
            let st = currentProject?.fileNamePattern ?? newFile.name;
            st = st.replace("{{name}}", newFile.name);
            st = st.replace("{{language}}", c.text.lng);
            st = st.replace("{{location}}", location?.names[0]?.text ?? "");
            st = st.replace("{{projectId}}", currentProject?.projectId ?? "");
            newFile.name = st;
        }
        return newFile;
    }
    return newFile;
}

// const SelectHeader = (p: HeaderProps<FileEntriesDownloadDto | FileEntryDownloadDto>) => {
//     const disabled = useSelector((s: ApplicationState) => s.cmsState.directoryAndFileActionsDisabled);
//     return (
//         <Button disabled={disabled} kind="fullTransparentButton" size="small" icon={p.isAllRowsSelected ? "checkbox checked" : "checkbox unchecked"} onClick={() => p.toggleAllRowsSelected(!p.isAllRowsSelected)} />
//     )
// };
// const SelectCell = (p: CMSCellPropsType) => {
//     const disabled = useSelector((s: ApplicationState) => s.cmsState.directoryAndFileActionsDisabled);
//     return (
//         <Button disabled={disabled} kind="fullTransparentButton" size="small" icon={p.row.isSelected ? "checkbox checked" : "checkbox unchecked"} onClick={() => p.row.toggleRowSelected(!p.row.isSelected)} />
//     )
// };



const CMSFilesGrid = (p: CMSFilesGridProps) => {
    const theme = React.useContext<Theme>(ThemeContext);
    const { routeParams, currentFiles, currentProject, contentLanguage, location, locations, filePosts, actionsDisabled } = useSelector(mapper, shallowCompare);




    const { t, currentLanguage } = useImgI18N("cms");
    const { showPreview, hiddenColumns, showArchivedFiles, info, filter, onEvent } = p;
    const id = routeParams?.id as string;
    const path = routeParams?.path as string[];
    const cmsTheme = useAppTheme<CMSTheme>("cmsTheme");
    const dispatch = useAppDispatch();

    const [fSize, setFSize] = React.useState<number>(0);
    const [dSize, setDSize] = React.useState<number>(0);


    const stillLoading = React.useMemo(() => {
        const pstr = _.join(path, "/");
        return _.find(Object.keys(currentFiles ?? {}), x => x === pstr) === undefined;
    }, [currentFiles, path]);
    //console.log("CMSFilesGrid.tsx::183 => stillLoading", stillLoading);


    React.useEffect(() => {
        dispatch(Actions.setCMSFilesGridSelectedRows([]));
        return () => {
            dispatch(Actions.setCMSFilesGridSelectedRows([]));
        }
    }, [dispatch]);

    const curPath = React.useMemo(() => {
        return path ? `${_.join(path, "/")}/` : "";
    }, [path]);

    const cur = React.useMemo(() => getCurrentDirectory(currentProject, path), [path, currentProject]);


    const [tableSortBy, setTableSortBy] = React.useState<SortingRule<FileEntriesDownloadDto | FileEntryDownloadDto>[]>([{ id: "name", desc: false }]);

    const getStyles = React.useMemo(() => (props: any, cell: CMSCellPropsType) => {
        // set styles of cell depending on cell value
        const ia = (cell.row.original as FileEntryDownloadDto).isArchived;
        const io = (cell.row.original as FileEntryDownloadDto).isProjectOutput;
        return [
            props,
            {
                style: {
                    backgroundColor: ia ? getColor({ theme }, "@veryLightGrey") : getColor({ theme }, theme.backgroundColor),
                    fontWeight: io ? 900 : undefined,
                },
            },
        ]
    }, [theme]);
    const cellProps = React.useMemo(() => (props: any, { cell }: { cell: any }) => getStyles(props, cell), [getStyles]);

    const language = contentLanguage ?? ImgI18N.getInstance().currentLanguage;
    const data = React.useMemo(() => {
        const toRet: Array<FileEntriesDownloadDto | FileEntryDownloadDto> = [];
        _.forEach(cur?.directories, d => {
            //console.log("CMSFilesGrid.tsx::195 => d.name", d.name);

            if (filter) {
                if (d.name?.toLowerCase().includes(filter.toLowerCase()))
                    toRet.push(d);
            }
            else
                toRet.push(d);
        });
        _.forEach(cur?.entries, e => {
            const res = cmsFindIndexPathAndFileInCurrentFiles(e, currentFiles);
            //console.log("CMSFilesGrid.tsx::175 => currentFiles", currentFiles);
            //console.log("CMSFilesGrid.tsx::226 => res", res);

            const found = res?.file;
            if (found) {
                const newFile = setFileEntryName(found, currentProject, language, location, filePosts);
                //console.log("CMSFilesGrid.tsx::209 => newFile", newFile);

                if (filter) {
                    if (newFile.name.toLowerCase().includes(filter.toLowerCase()))
                        toRet.push(newFile);
                }
                else
                    toRet.push(newFile);
            }
        })
        return _.filter(toRet, e => showArchivedFiles ? true : !(e as any)?.isArchived);
    }, [cur, currentFiles, location, language, showArchivedFiles, filePosts, currentProject, filter]);

    const goTo = React.useMemo(() => (fileId: string) => (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (ev.ctrlKey || ev.shiftKey)
            return;
        gotoNamedRoute("cms_file", { projectId: id, id: fileId, path: curPath });
    }, [id, curPath]);

    const goToDir = React.useMemo(() => (dir: FileEntriesDownloadDto) => (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (ev.ctrlKey || ev.shiftKey)
            return;
        gotoNamedRoute("cms_dir", { id: id, path: path ? _.join([...path, dir.name], "/") : (dir.name ?? "") });
    }, [id, path]);

    const setColumnOrderMethod = React.useCallback((cols: string[]) => {
        setColumnOrder(o => ["checkbox", ...cols, "buttons"]);
        // eslint-disable-next-line
    }, []);

    const addRowClick = React.useMemo(() => (row: Row<FileEntriesDownloadDto | FileEntryDownloadDto>) => (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const sel = row.isSelected;
        row.toggleRowSelected(!sel);
    }, []);

    const columns = React.useMemo(() => {
        const toRet: CMSColumnType[] = [
            {
                id: "checkbox",
                Header: <BC>
                    <Button
                        icon="info"
                        tooltip={{ tooltipText: t("if you want to make more than one selection, mark the desired files or directories by clicking the checkboxes, or by holding down the CTRL key and clicking in the rows."), position: "right", offsetY: -15 }}
                        kind='fullTransparentButton'
                        disabled />
                </BC>,
                accessor: (d) => (d as FileEntryDownloadDto)?.contents ? true : false,
                Cell: (cur: CMSCellPropsType) => {
                    return (
                        <div onClick={stopEvent}>
                            {(cur.row.original as FileEntryDownloadDto)?.isArchived ?
                                <div />
                                :
                                <div onClick={addRowClick(cur.row)} style={{ cursor: "pointer" }}>
                                    <Icon name={cur.row.isSelected ? 'checkbox checked' : 'checkbox unchecked'} />
                                </div>
                            }
                        </div>
                    );
                },
                width: 40,
                disableSortBy: true,
            },
            {
                id: "name",
                Header: t("folder / file"),
                accessor: "name",
                Cell: (cur: CMSCellPropsType) => {
                    return (
                        <T.FgSelectedRow
                            selected={cur.row.isSelected}
                            style={{
                                width: (belowBreakpointOrEqual({ theme }, "desktop") ?
                                    (belowBreakpointOrEqual({ theme }, "tablet") ? 250 : 350)
                                    : 400)
                            }}
                        >
                            {(cur.row.original as FileEntryDownloadDto)?.contents !== undefined ?
                                <div
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "30px 1fr",
                                    }}

                                >
                                    <div style={{
                                        cursor: actionsDisabled ? undefined : "pointer",
                                        alignSelf: "center",
                                        display: "flex",
                                        opacity: (cur.row.original as FileEntryDownloadDto)?.isArchived ? 0.5 : 1,
                                        filter: `grayscale(${(cur.row.original as FileEntryDownloadDto)?.isArchived ? 1 : 0})`
                                    }}
                                        onClick={actionsDisabled ? undefined : goTo((cur.row.original as FileEntryDownloadDto).id)}
                                    >
                                        <CMSFileEntryAppIcon fileEntry={cur.row.original as FileEntryDownloadDto} margin={"0px"} />
                                    </div>
                                    <T.DirViewFileNameContainer>
                                        <span style={{ cursor: actionsDisabled ? undefined : "pointer", color: (cur.row.original as FileEntryDownloadDto)?.isArchived ? "#999999" : undefined }} onClick={actionsDisabled ? undefined : goTo((cur.row.original as FileEntryDownloadDto).id)} title={cur.value}>
                                            {cur.value}
                                        </span>
                                    </T.DirViewFileNameContainer>
                                </div> :
                                <div
                                    className="folder"
                                >
                                    <div
                                        onClick={actionsDisabled ? undefined : goToDir((cur.row.original as FileEntriesDownloadDto))}
                                        style={{ cursor: actionsDisabled ? undefined : "pointer", lineHeight: "40px" }}
                                    >
                                        <Icon name="folder" marginTop={4} style={{ float: "left", marginRight: 10 }} />
                                    </div>
                                    <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                        <span
                                            style={{ cursor: actionsDisabled ? undefined : "pointer", lineHeight: "40px" }}
                                            title={cur.value}
                                            onClick={actionsDisabled ? undefined : goToDir((cur.row.original as FileEntriesDownloadDto))}
                                        >
                                            {cur.value}
                                        </span>
                                    </div>
                                </div>
                            }
                            {/* {(cur.row.original as FileEntryDownloadDto)?.contents !== undefined ?
                                <div
                                    style={{ cursor: "pointer", lineHeight: "40px", position: "relative" }}
                                    onClick={goTo((cur.row.original as FileEntryDownloadDto).id)}
                                >
                                    <div style={{ display: "flex", alignSelf: "center", float: "left", width: 30 }}>
                                        <CMSFileEntryAppIcon fileEntry={cur.row.original as FileEntryDownloadDto} margin={"0px"} />
                                    </div>
                                    <div style={{
                                        overflow: "hidden",
                                        lineHeight: "20px",
                                        width: "calc(100% - 30px)",
                                        alignSelf: "center",
                                        display: "flex"
                                    }}>
                                        <span title={cur.value}>{cur.value}</span>
                                    </div>
                                </div> :
                                <div style={{ cursor: "pointer", lineHeight: "40px" }} onClick={goToDir((cur.row.original as FileEntriesDownloadDto))} className="folder">
                                    <Icon name="folder" marginTop={4} style={{ float: "left", marginRight: 10 }} />
                                    <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                        <span title={cur.value}>{cur.value}</span>
                                    </div>
                                </div>
                            } */}
                        </T.FgSelectedRow >
                    );
                },
                sortType: sortFiles,
            },
            {
                id: "size",
                Header: t("size"),
                accessor: "name",
                Cell: (cur: CMSCellPropsType) => {
                    const size = (cur.row.original as any)?.size;
                    const s = size ? shortenBytes(size, 0) : undefined;
                    return (
                        <T.FgSelectedRow
                            selected={cur.row.isSelected}
                            style={{ textAlign: "right" }}
                        >
                            {(cur.row.original as FileEntryDownloadDto)?.contents !== undefined ?
                                <div style={{ color: (cur.row.original as FileEntryDownloadDto)?.isArchived ? "#999999" : undefined }}>
                                    {s ? t("{{count}} {{unit}}", { count: s[0], unit: s[1] }) : ""}
                                </div> :
                                <div />
                            }

                        </T.FgSelectedRow >
                    );
                },
                width: 40,
                //disableSortBy: true,
                sortType: sortBySize,
            },
            {
                id: "state",
                Header: t("state"),
                width: 100,
                accessor: (d) => (d as FileEntryDownloadDto)?.contents ? d : undefined,
                Cell: (cur: CMSCellPropsType) => {
                    const ele = cur.row.original as FileEntryDownloadDto;

                    if (cur) {
                        const fe = cur.value as FileEntryDownloadDto;
                        return (
                            <T.FgSelectedRow selected={cur.row.isSelected} style={{ lineHeight: "40px", width: 100 }} >

                                <T.FgSelectedRowStatusIcon>
                                    {fe?.isArchived &&
                                        <Tooltip background={"@darkGrey"} fontColor={"@mainBackground"} cursor="default" tooltipText={t("archived file")} noMargin notInline position="top" offsetY={-100} >
                                            <T.TTIconContainer>
                                                <Icon color={"@darkGrey"} name={fe?.isArchived ? "box" : "empty"} />
                                            </T.TTIconContainer>
                                        </Tooltip>
                                    }
                                </T.FgSelectedRowStatusIcon>

                                <T.FgSelectedRowStatusIcon>
                                    {fe?.isProjectOutput && !fe?.isReference &&
                                        <Tooltip background={"@accentOrange"} fontColor={"@mainBackground"} cursor="default" tooltipText={t("file is in workflow: {{state}}", { state: ele.workflowState ?? "" })} noMargin notInline position="top" offsetY={-100} >
                                            <T.TTIconContainer>
                                                <Icon color={"@accentOrange"} name="flag" />
                                            </T.TTIconContainer>
                                        </Tooltip>
                                    }
                                </T.FgSelectedRowStatusIcon>

                                <T.FgSelectedRowStatusIcon>
                                    {fe?.isPublished && !fe?.isReference &&
                                        <Tooltip background={"@accentGreen"} fontColor={"@mainBackground"} cursor="default" tooltipText={t("published file")} noMargin notInline position="top" offsetY={-100}>
                                            <T.TTIconContainer>
                                                <Icon name="published" color={"@accentGreen"} />
                                            </T.TTIconContainer>
                                        </Tooltip>
                                    }
                                </T.FgSelectedRowStatusIcon>

                                <T.FgSelectedRowStatusIcon>
                                    {fe?.isReference &&
                                        <Tooltip background={"@accentBlue"} fontColor={"@mainBackground"} cursor="default" tooltipText={t("file reference")} noMargin notInline position="top" offsetY={-100}>
                                            <T.TTIconContainer>
                                                <Icon color={"@accentBlue"} name="file reference" style={{ float: "left" }} />
                                            </T.TTIconContainer>
                                        </Tooltip>
                                    }
                                </T.FgSelectedRowStatusIcon>

                                <T.FgSelectedRowStatusIcon>
                                    {fe?.locked &&
                                        <Tooltip background={"@accentRed"} fontColor={"@mainBackground"} cursor="default" tooltipText={t("file is locked")} noMargin notInline position="top" offsetY={-100}>
                                            <T.TTIconContainer>
                                                <Icon color={"@accentRed"} name="lock" style={{ float: "left" }} />
                                            </T.TTIconContainer>
                                        </Tooltip>
                                    }
                                </T.FgSelectedRowStatusIcon>
                            </T.FgSelectedRow >
                        )
                    }
                    else
                        return <div />;
                },
                sortType: sortByState,
            },
            {
                id: "version",
                Header: t("ver°"),
                accessor: (d) => (d as FileEntryDownloadDto)?.version ? (d as FileEntryDownloadDto)?.version : "",
                Cell: (cur: CMSCellPropsType) => <div style={{ maxWidth: 20, textAlign: "right", color: (cur.row.original as FileEntryDownloadDto)?.isArchived ? "#999999" : undefined }}>{cur.value}</div>,
                sortType: sortByVersion,
            },
            {
                id: "changed",
                Header: t("changed"),
                accessor: (d) => (d as FileEntryDownloadDto)?.changed ? getTranslatedStandardDate((d as FileEntryDownloadDto)?.changed) : "",
                Cell: (cur: CMSCellPropsType) => {
                    return <div style={{ color: (cur.row.original as FileEntryDownloadDto)?.isArchived ? "#999999" : undefined }}>{cur.value}</div>
                },
                sortType: sortByDate,
            },
            {
                id: "workflow",
                Header: () => t("workflow"),
                accessor: (d) => "",
                Cell: (cur: CMSCellPropsType) => {
                    const ele = cur.row.original as FileEntryDownloadDto;
                    if (ele.type === "FileEntry" && ele.isProjectOutput)
                        return <div style={{ color: (cur.row.original as FileEntryDownloadDto)?.isArchived ? "#999999" : undefined }}>{ele.workflowName ?? ""}</div>
                    else
                        return <div />;
                },
                sortType: sortByStringValue("workflowName")
            },
            {
                id: "workflow state",
                Header: () => t("workflow state"),
                accessor: (d) => "",
                Cell: (cur: CMSCellPropsType) => {
                    const ele = cur.row.original as FileEntryDownloadDto;
                    if (ele.type === "FileEntry" && ele.isProjectOutput)
                        return <div style={{ color: (cur.row.original as FileEntryDownloadDto)?.isArchived ? "#999999" : undefined }} title={ele.isReference ? t("foreign workflow: {{workflow}}", { workflow: ele.workflowName }) : ele.workflowName}>{ele.workflowState ?? ""}</div>
                    else
                        return <div />;
                },
                sortType: sortByStringValue("workflowState")
            },
            {
                id: "languages",
                maxWidth: 60,
                Header: () => <div style={{ width: 60, textAlign: "center", marginLeft: -5, cursor: "default", pointerEvents: "none", userSelect: "none" }} title="target content locations and languages"><Icon name="language" /></div>,
                accessor: (d) => (d as FileEntryDownloadDto)?.contents ? true : false,
                disableSortBy: true,
                Cell: (cur: CMSCellPropsType) => {
                    const contents = (cur.row.original as FileEntryDownloadDto).contents;
                    const themeColors = useThemePart(t => t.colors);
                    const [contentLocationsWithFlags, size] = React.useMemo(() => {
                        const result: { [id: string]: string[] } = {};
                        let totalSize = 0;

                        if (contents) {
                            Object.keys(contents).forEach(key => {
                                const location = locations?.find(loc => loc.id === key);
                                const locationName = getTranslated(location?.names, currentLanguage)?.text || '';

                                const medias = contents[key].contents || [];
                                const languages = medias.map(media => media.lng).filter(Boolean) as string[];

                                if (languages.length > 0) {
                                    result[locationName] = languages;
                                    totalSize += medias.length;
                                }
                            });
                        }
                        return [result, totalSize] as const;
                    }, [contents]);

                    if (size === 0) {
                        return null;
                    }

                    return (
                        <Button
                            kind="fullTransparentButton"
                            content={size.toString()}
                            fluid
                            disabled
                            tooltip={{
                                tooltipText: "",
                                tooltipContent: <div>
                                    <div
                                        style={{
                                            fontWeight: "bolder",
                                            lineHeight: "40px",
                                            textAlign: "left",
                                            paddingLeft: 15,
                                            background: themeColors.veryLightGrey,
                                            width: "calc(100% + 26px)",
                                            transform: "translate(-13px, -13px)"
                                        }}
                                    >
                                        {t("target content locations and languages")}
                                    </div>
                                    <Container fixedLocationWidth={false} style={{ rowGap: 5 }}>
                                        {Object.entries(contentLocationsWithFlags).map(([locationName, languages]) => (
                                            <React.Fragment key={locationName}>
                                                <div style={{ marginBottom: 5 }}>
                                                    <Icon
                                                        name="location"
                                                        style={{ float: "left", marginRight: 5 }}
                                                        marginTop={-1}
                                                        color='@lightGrey'
                                                    />
                                                    {locationName}
                                                    <Icon
                                                        name="chevron right double"
                                                        style={{ float: "right", marginLeft: 5, marginRight: -5 }}
                                                        marginTop={-1}
                                                        color='@lightGrey'
                                                    />
                                                </div>
                                                <div>
                                                    {languages.map(lang => (
                                                        <div
                                                            style={{
                                                                display: "inline-flex",
                                                                marginRight: 5,
                                                                textAlign: "right"
                                                            }}
                                                            key={lang}
                                                        >

                                                            <Flag
                                                                hoverable={false}
                                                                size={18}
                                                                marginTop={-2}
                                                                name={lang as ISO_3166 | RFC_4646}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </Container>
                                </div>,
                                tooltipContainerMaxWidth: "350px",
                                noMargin: true,
                            }}
                        />
                    );
                }
            },

            {
                id: "button",
                Header: (t: CMSTableInstanceType) => {
                    return <CMSTableMenu
                        columns={t.columns}
                        setColumnOrder={setColumnOrderMethod}
                        toggleRowSelected={t.toggleRowSelected}
                        fSize={fSize}
                        dSize={dSize}
                    />
                },
                accessor: (d) => (d as FileEntryDownloadDto)?.contents ? true : false,
                Cell: (cur: CMSCellPropsType) => {
                    return (
                        <div onClick={stopEvent} style={{ height: 40 }}>
                            {cur.value ?
                                <CMSFileMenu
                                    info={info}
                                    current={cur.row.original as FileEntryDownloadDto}
                                    showPreview={showPreview}
                                    selected={cur.row.isSelected}
                                    selectRow={cur.row.toggleRowSelected}
                                    onLinkCopied={onEvent}
                                />
                                :
                                <CMSDirectoryMenu
                                    current={cur.row.original as FileEntriesDownloadDto}
                                    selected={cur.row.isSelected}
                                    selectRow={cur.row.toggleRowSelected}
                                    onLinkCopied={onEvent}
                                />
                            }
                        </div>
                    );
                },
                width: 40,
                disableSortBy: true,
            },
        ]
        return toRet;
    }, [t, showPreview, goTo, goToDir, setColumnOrderMethod, info, theme, actionsDisabled, onEvent, addRowClick, fSize, dSize, locations, currentLanguage]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        //allColumns,
        selectedFlatRows,
        //getToggleHideAllColumnsProps,
        state,
        setColumnOrder,
        toggleAllRowsSelected,
    } = useTable({
        columns,
        data,
        initialState: {
            hiddenColumns,
            sortBy: tableSortBy ?? [],
            //hiddenColumns: ["workflow"],
            //columnOrder
        }

    }, useSortBy, useRowSelect, useColumnOrder);

    React.useEffect(() => {
        let ff = 0;
        let dd = 0;  // folders
        _.forEach(rows, r => {
            if ((r.original as FileEntriesDownloadDto).entries !== undefined) {
                dd++;
            }
            if ((r.original as FileEntryDownloadDto).categories !== undefined) {
                ff++;
            }
        });
        setFSize(ff);
        setDSize(dd);
    }, [rows]);

    const rowClick = React.useMemo(() => (row: Row<FileEntriesDownloadDto | FileEntryDownloadDto>) => (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        //console.log("CMSFilesGrid.tsx::476 => ev.ctrlKey", ev.ctrlKey);
        const sel = row.isSelected;
        if (!ev.ctrlKey)
            toggleAllRowsSelected(false);
        row.toggleRowSelected(!sel);

    }, [toggleAllRowsSelected]);



    React.useEffect(() => {
        dispatch(Actions.setCMSFilesGridSelectedRows(_.map(selectedFlatRows, s => s.original)));
    }, [selectedFlatRows, dispatch]);

    React.useEffect(() => {
        if (state?.sortBy !== tableSortBy) {
            setTableSortBy(state.sortBy);
        }
    }, [state, tableSortBy])



    if (!cur)
        return <div />
    return (
        <T.FgC>
            <table
                cellPadding={0}
                cellSpacing={0}
                {...getTableProps() as any}
                style={{ width: "100%", position: "relative", borderCollapse: "collapse" }}
            >
                <thead>
                    {headerGroups.map(headerGroup => (
                        <T.FgTRH {...headerGroup.getHeaderGroupProps() as any}>
                            {headerGroup.headers.map(column => {
                                const sortDirection = (tableSortBy && tableSortBy.length > 0 && tableSortBy[0].desc);
                                const tabbedView = cmsTheme.showCmsTableSortingAsTabs;
                                return (
                                    <T.FgTH
                                        {...column.getHeaderProps(column.getSortByToggleProps()) as any}
                                        tabbedView={tabbedView ?? false}
                                        isSorted={column.isSorted ?? false}
                                        title={column.canSort ? t("sort by {{columnname}}", { columnname: column.id }) : ""}
                                        style={{
                                            color: column.isSorted ? theme.colors.darkerGrey : undefined,
                                            background: column.isSorted ? theme.colors.mainBackground : theme.colors.mainBackground,
                                            cursor: column.disableSortBy === undefined ? "pointer" : "default",
                                            width: column.width,
                                            minwidth: column.minWidth,
                                            maxWidth: column.maxWidth,
                                            heigth: 40,
                                            position: "sticky",
                                            top: 0,
                                            scope: "col",
                                            zIndex: 1,
                                            borderBottom: "none",
                                            borderWidth: tabbedView ? 1 : undefined,
                                            borderStyle: tabbedView ? "solid" : undefined,
                                            borderLeftColor: tabbedView ? column.isSorted ? theme.colors.mainBackground : theme.colors.mainBackground : undefined,
                                            borderRightColor: tabbedView ? column.isSorted ? theme.colors.middleLightGrey : theme.colors.mainBackground : undefined,
                                            borderTopColor: tabbedView ? column.isSorted ? theme.colors.middleLightGrey : theme.colors.mainBackground : undefined,
                                            borderBottomColor: tabbedView ? column.isSorted ? theme.colors.mainBackground : theme.colors.middleLightGrey : undefined,
                                        }}
                                    >
                                        <div style={{
                                            display: column.id === "button" || column.id === "checkbox" ? "block" : "grid",
                                            gridTemplateColumns: column.id === "button" || column.id === "checkbox" ? "unset" : "max-content 1fr",
                                        }}>
                                            <div style={{ alignSelf: "center" }}>
                                                {column.render('Header')}
                                            </div>
                                            {column.id !== "button" && column.id !== "checkbox" &&
                                                <div style={{ paddingLeft: 10 }}>
                                                    <Icon name={column.isSorted ? sortDirection ? "arrow down" : "arrow up" : "empty"} color={column.isSorted ? sortDirection ? theme.colors.darkerGrey : theme.colors.darkerGrey : theme.colors.middleLightGrey} />
                                                </div>
                                            }
                                        </div>
                                    </T.FgTH>
                                )
                            })}
                        </T.FgTRH>
                    ))}
                </thead>
                <tbody {...getTableBodyProps() as any}>
                    {path && path.length > 0 &&
                        <T.FgTR>
                            <T.FgTD colSpan={columns.length}>
                                <Button
                                    floated='right'
                                    icon="folder up"
                                    onClick={gotoNamedRouteMethod("cms_dir", { id, path: _.join(_.dropRight(path, 1), '/') })}
                                    kind="transparentButton"
                                    tooltip={{ tooltipText: "up" }}
                                />
                            </T.FgTD>
                        </T.FgTR>
                    }
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <T.FgTR {...row.getRowProps() as any} key={i} onClick={rowClick(row)}>
                                {row.cells.map(cell => {
                                    return <T.FgTD
                                        {...cell.getCellProps(cellProps) as any}
                                        style={{
                                            backgroundColor: row.isSelected ? fadeColor({ theme }, "@accentBlue", 8) : "unset",
                                            color: row.isSelected ? lightenColor({ theme }, "@accentBlue", 90) : "inherit",
                                            cursor: row.isSelected ? "default" : "cell",
                                            height: 40,
                                            lineHeight: "unset",
                                        }}
                                    >
                                        {cell.render('Cell')}
                                    </T.FgTD>;
                                })}
                            </T.FgTR>
                        )
                    })}
                    {stillLoading &&
                        <T.FgTR noHover>
                            <T.FgTD colSpan={columns.length} style={{ color: theme.colors.lightGrey }}>
                                <LoaderInline active infoText={t("loading files")} />
                            </T.FgTD>
                        </T.FgTR>
                    }
                    {(cur?.entries ?? []).length === 0 && (cur?.directories ?? []).length === 0 &&
                        <T.FgTR noHover>
                            <T.FgTD colSpan={columns.length} style={{ color: theme.colors.lightGrey }}>
                                <Icon name="info" style={{ float: "left", marginRight: 5 }} />
                                {t("directory is empty")}
                            </T.FgTD>
                        </T.FgTR>
                    }
                    <T.FgTR style={{ height: 20 }} noHover  >
                        <T.FgTD colSpan={columns.length} style={{ height: 20 }} />
                    </T.FgTR>
                </tbody>
            </table>
        </T.FgC>
    )
};
export default CMSFilesGrid;