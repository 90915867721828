import { ContentPostContentLocationDownloadDto } from 'collaboration-service';
import { Flag, Icon, ISO_3166, RFC_4646, styled, Tooltip, useThemePart } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';


interface CMSFileVersionsInfoProps {
    locAndLngs: Array<{ location: ContentPostContentLocationDownloadDto, lngs: string[] }>
    showLanguageText?: boolean;
    hideTooltip?: boolean;
    fixedLocationWidth?: boolean;
    tooltipHeadline?: string;
}

export const Container = styled.div<{ fixedLocationWidth: boolean }>`
    display: grid;
    grid-template-columns: ${p => p.fixedLocationWidth ? "200px" : "max-content"} 1fr;
    column-gap: 10px;
    grid-template-rows: max-content;
    text-align: left;
    overflow: hidden;
`;

const CMSFileVersionsInfo = (p: CMSFileVersionsInfoProps) => {
    const { locAndLngs, showLanguageText, hideTooltip, fixedLocationWidth, tooltipHeadline } = p;
    const { t } = useImgI18N("general");

    const themeColors = useThemePart(t => t.colors);
    return (
        <>
            {tooltipHeadline &&
                <div style={{ fontWeight: "bolder", lineHeight: "40px", textAlign: "left", paddingLeft: 15, background: themeColors.veryLightGrey, width: "calc(100% + 26px)", transform: "translate(-13px, -13px)" }}>
                    {tooltipHeadline}
                </div>
            }
            <Container fixedLocationWidth={fixedLocationWidth ?? false}>
                {_.map(_.compact(locAndLngs), (ll, i) => {
                    return (
                        <React.Fragment key={i}>
                            <div>
                                <Icon name="location" style={{ float: "left", marginRight: 5 }} marginTop={-1} color='@lightGrey' />
                                {getTranslated(ll.location.names)?.text}
                                <Icon name="chevron right double" style={{ float: "right", marginLeft: 5, marginRight: -5 }} marginTop={-1} color='@lightGrey' />
                            </div>
                            <div >
                                <React.Fragment key={i}>
                                    {_.map(ll.lngs, (lang, j) => {
                                        const lngAcronym = lang.split("-")[0];
                                        const nv = t(lngAcronym);
                                        const flag = <Flag hoverable={false} size={18} marginTop={0} name={lang as ISO_3166 | RFC_4646} />;
                                        return (
                                            <div style={{ display: "inline-flex", marginRight: 10, textAlign: "right" }} key={j}>
                                                <div style={{ display: "grid", gridTemplateColumns: "max-content max-content", columnGap: 5 }}>
                                                    {hideTooltip ? flag : <Tooltip tooltipText={nv} noMargin notInline>{flag}</Tooltip>}
                                                    {showLanguageText ? <div>{nv}</div> : <div />}
                                                </div>
                                            </div>
                                        );
                                    })
                                    }
                                </React.Fragment>
                            </div>
                        </React.Fragment>
                    );
                })}


            </Container>
        </>
    );
}
export default CMSFileVersionsInfo;