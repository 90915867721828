import { getColor, ImgThemeConsumer } from 'imaginarity-react-ui';
import * as React from 'react';
import { DonutContainer } from './DonutChartClasses';

export interface DonutChartProps {
    width: number;
    donePercentage: number;
    activeColor: string;
    inactiveColor: string;
    shadowColor?: string;
    rounded?: boolean;
    shadowed?: boolean;
    fontColor?: string;
}

export interface DonutChartState {
}

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
};

const svgDescribeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    const d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");
    return d;
};
class DonutChart extends React.Component<DonutChartProps, DonutChartState> {
    constructor(props: DonutChartProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        return (
            <ImgThemeConsumer>
                {(theme) => {
                    const p = this.props;
                    const done = p.donePercentage === 0 ? 0 : p.donePercentage;
                    const percent = 359.99999 * (done / 100);
                    const width = p.width;
                    const center = width / 2;
                    const radius = width * 0.3;
                    const rounded = p.rounded ?? theme.donut.rounded;
                    const shadowed = p.shadowed ?? theme.donut.shadowed;
                    return (
                        <DonutContainer>
                            <svg xmlns="http://www.w3.org/2000/svg" height={p.width} width={p.width}>
                                <defs>
                                    <filter id="shadow">
                                        <feDropShadow
                                            dx={0}
                                            dy={0}
                                            stdDeviation={p.width * 0.05}
                                            floodColor={p.shadowColor}
                                            floodOpacity={0.8} />
                                    </filter>
                                    <linearGradient id="gradientActive" x1="0" y1="0" x2="1" y2="1">
                                        <stop offset="0%" stopColor="rgba(255,255,255,0.0)" />
                                        <stop offset="100%" stopColor="rgba(255,255,255,0.2)" />
                                    </linearGradient>

                                    <linearGradient id="gradientInactive" x1="0" y1="0" x2="1" y2="1">
                                        <stop offset="0%" stopColor="rgba(0,0,0,0.15)" />
                                        <stop offset="100%" stopColor="rgba(0,0,0,0.0)" />
                                    </linearGradient>
                                </defs>
                                <path
                                    d={svgDescribeArc(center, center, radius, percent, 359.99999)}
                                    // stroke={p.inactiveColor}
                                    stroke={getColor({ theme }, p.inactiveColor)}
                                    fill="none"
                                    strokeWidth={center * 0.2}
                                />
                                {shadowed &&
                                    <path
                                        d={svgDescribeArc(center, center, radius, percent + 30, 330)}
                                        stroke="url(#gradientInactive)"
                                        fill="none"
                                        strokeWidth={center * 0.05}
                                        strokeLinejoin={rounded ? "round" : "miter"}
                                        strokeLinecap={rounded ? "round" : "butt"}
                                    />
                                }
                                <path
                                    d={svgDescribeArc(center, center, radius, 0, percent)}
                                    // stroke={p.activeColor}
                                    stroke={getColor({ theme }, p.activeColor)}
                                    //strokeDasharray={300}
                                    fill="none"
                                    strokeWidth={center * 0.4}
                                    strokeLinejoin={rounded ? "round" : "miter"}
                                    strokeLinecap={rounded ? "round" : "butt"}
                                />
                                {shadowed &&
                                    <path
                                        d={svgDescribeArc(center, center, radius, 30, percent - 5)}
                                        stroke="url(#gradientActive)"
                                        fill="none"
                                        strokeWidth={center * 0.1}
                                        strokeLinejoin={rounded ? "round" : "miter"}
                                        strokeLinecap={rounded ? "round" : "butt"}
                                    />
                                }
                                {p.fontColor &&
                                    <text x={center} y={center}
                                        textAnchor="middle"
                                        // style={{ fontWeight: "bold", fontSize: "0.9em", fill: p.fontColor }}
                                        style={{ fontWeight: "bold", fontSize: "0.9em", fill: getColor({ theme }, p.fontColor) }}
                                    >
                                        {Math.floor(done)}%
                                    </text>
                                }
                            </svg>
                        </DonutContainer>
                    )
                }}
            </ImgThemeConsumer>
        );
    }
}
export default DonutChart;