import { PlaylistStateDownloadDto, PlaylistUploadDto } from 'collaboration-service';
import { Button, Checkbox, Icon, Input, Loader, aboveBreakpoint, aboveBreakpointOrEqual, belowBreakpointOrEqual, useThemePart } from 'imaginarity-react-ui';
import _ from 'lodash';
import Carousel from 'nuka-carousel';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { WorkingActions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { countNodeItemsToBeDonePerNode, getCriticalAndPendingCounts, getPlaylistStateDueDate, getProgressStatus, shallowCompare } from 'services/Helpers';
import { gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { isAdmin } from 'services/StoreDependantHelpers';
import { ThemeContext } from 'styled-components';
import { getSlideProperties } from 'views/MyHome/myHomeHelpers';
import PlaylistOverviewCarouselItem from './PlaylistOverviewCarouselItem';
import PlaylistOverviewItem from './PlaylistOverviewItem';
import { PlaylistSC as T } from './PlaylistSC';

const mapper = (state: ApplicationState) => ({
    playlistStatesTopic: state.playlistsState.playlistStatesTopic,
    contentLanguage: state.contentLanguage,
});

interface PlaylistOverviewProps {
}

type SortBy = "assigned" | "name" | "duedate" | "duration" | "progress";
const PlaylistOverview = (p: PlaylistOverviewProps) => {
    const { playlistStatesTopic, contentLanguage } = useSelector(mapper, shallowCompare);

    const dispatch = useAppDispatch();
    const { t } = useImgI18N("playlist");
    const contentWidth = useThemePart(x => x.content.width);
    const { slidesToShow, slidesGap, slideWidth } = getSlideProperties(contentWidth);
    const [showBtns, setShowBtns] = React.useState<boolean>(false);
    const [sortedLists, setSortedLists] = React.useState<{ undone: PlaylistStateDownloadDto[], done: PlaylistStateDownloadDto[], optional: PlaylistStateDownloadDto[], undoneStarted: PlaylistStateDownloadDto[] }>({ undone: [], done: [], optional: [], undoneStarted: [] });
    const [sortBy, setSortBy] = React.useState<SortBy>("duedate");
    const [reverseSort, setReverseSort] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    const listLinear = _.filter(sortedLists.undone, f => (f.playlist as PlaylistUploadDto).sequencingMode === "Linear");
    const hasMandatoryEntries = sortedLists.undone.length > 0;
    const hasOptionalEntries = sortedLists.optional.length > 0;

    React.useEffect(() => {
        dispatch(WorkingActions.WIKI.loadPlaylistState());
    }, [dispatch]);

    const loading = playlistStatesTopic === undefined;

    const filteredLists = React.useMemo(() => {
        const filteredNotDone = _.filter(playlistStatesTopic, p =>
            p.state !== "Finished" &&
            p.optional === false &&
            p.playlist.headlines.some(headline => headline.text.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        const filterDone = _.filter(playlistStatesTopic,
            p => p.state === "Finished" &&
                p.playlist.headlines.some(headline => headline.text.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        const filteredOptional = _.filter(playlistStatesTopic,
            p => p.state !== "Finished" &&
                p.optional === true &&
                p.playlist.headlines.some(headline => headline.text.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        const filteredNotDoneStarted = _.filter(playlistStatesTopic, p => {
            let criticalCount = 0;
            criticalCount += countNodeItemsToBeDonePerNode(p, p.curNodes);
            criticalCount += countNodeItemsToBeDonePerNode(p, p.prevNodes);
            criticalCount += countNodeItemsToBeDonePerNode(p, p.nextNodes);

            return (
                p.state !== "Finished" &&
                p.optional === false &&
                criticalCount > 0 &&
                p.playlist.headlines.some(headline => headline.text.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        });
        return [filteredNotDone, filterDone, filteredOptional, filteredNotDoneStarted]
    }, [playlistStatesTopic, searchTerm]);


    React.useEffect(() => {
        const [filteredNotDone, filterDone, filteredOptional, filteredNotDoneStarted] = filteredLists;
        let sortFunction: _.ListIteratee<PlaylistStateDownloadDto>;
        switch (sortBy) {
            case 'assigned':
                sortFunction = (p: PlaylistStateDownloadDto) => p.assignmentDate;
                break;
            case 'name':
                sortFunction = (p: PlaylistStateDownloadDto) => getTranslated(p.playlist.headlines, contentLanguage)?.text ?? "";
                break;
            case 'duedate':
                sortFunction = getPlaylistStateDueDate;
                break;
            case 'duration':
                sortFunction = (p: PlaylistStateDownloadDto) => {
                    const durations = p.curNodes
                        .concat(p.prevNodes)
                        .concat(p.nextNodes)
                        .map(node => node.durationInMinutes || 0);

                    return durations.reduce((total, duration) => total + duration, 0);
                };
                break;
            case 'progress':
                sortFunction = (p: PlaylistStateDownloadDto) => {
                    const curNode = p.curNodes[0];
                    const progressStatus = getProgressStatus(curNode, p);
                    const toBeDoneResult = getCriticalAndPendingCounts(p);

                    // by percent done
                    // const x = toBeDoneResult.itemsToBeDone > 0 ? Math.floor((toBeDoneResult.criticalCount / toBeDoneResult.itemsToBeDone) * 100) : 0;

                    // by criticalCount
                    const x = toBeDoneResult.criticalCount;


                    switch (progressStatus) {
                        case "done":
                            return 0 + x;
                        case "overdue":
                            return 100 + x;
                        case "warning":
                            return 200 + x;
                        case "on Track":
                            return 300 + x;
                    }

                };
                break;
        }

        if (reverseSort)
            setSortedLists({
                undone: _.reverse(_.sortBy(filteredNotDone, sortFunction)),
                done: _.reverse(_.sortBy(filterDone, sortFunction)),
                optional: _.reverse(_.sortBy(filteredOptional, sortFunction)),
                undoneStarted: _.reverse(_.sortBy(filteredNotDoneStarted, sortFunction)),
            });
        else
            setSortedLists({
                undone: _.sortBy(filteredNotDone, sortFunction),
                done: _.sortBy(filterDone, sortFunction),
                optional: _.sortBy(filteredOptional, sortFunction),
                undoneStarted: _.sortBy(filteredNotDoneStarted, sortFunction),
            });

    }, [sortBy, contentLanguage, filteredLists, reverseSort])

    const theme = React.useContext(ThemeContext);
    const isMobile = belowBreakpointOrEqual({ theme }, "largeMobile");
    const isTablet = aboveBreakpoint({ theme }, "largeMobile");
    const isLaptop = aboveBreakpointOrEqual({ theme }, "tablet");
    const isDesktop = aboveBreakpoint({ theme }, "desktop");
    const admin = isAdmin("WIKI");
    const adminAndNotMobile = admin && !isMobile;
    const showDescs = contentWidth > 800;

    const setSort = React.useCallback((by: SortBy) => () => {
        setSortBy(a => {
            setReverseSort(b => by === a ? !b : false);
            return by;
        })
    }, [])





    return (
        <div style={{ padding: isMobile ? 10 : 0, paddingBottom: 50 }}>
            {loading ? <Loader active infoText={t("loading playlists")} size='small' light /> :
                <>
                    {hasMandatoryEntries &&
                        <>
                            <T.Headline>
                                {searchTerm === "" ? t("my already processed paylists") : t("my filtered playlists")}
                            </T.Headline>
                            <T.CarouselContainer>
                                <Carousel
                                    slidesToShow={slidesToShow}
                                    dragging={listLinear.length > slidesToShow}
                                    slideWidth={slideWidth}
                                    cellSpacing={slidesGap}
                                    slidesToScroll={slidesToShow}
                                    renderCenterRightControls={null}
                                    renderCenterLeftControls={null}
                                    renderBottomCenterControls={null}
                                    renderTopRightControls={slidesToShow >= sortedLists.undone.length ? null : ({ previousSlide, nextSlide, currentSlide, slideCount }) => (listLinear.length > slidesToShow &&
                                        <>
                                            {contentWidth >= 480 &&
                                                <T.CarouselButtonLeft onClick={previousSlide} disabled={currentSlide === 0}>
                                                    <Icon name="chevron left" size="22px" />
                                                </T.CarouselButtonLeft>
                                            }
                                            {contentWidth >= 480 &&
                                                <T.CarouselButtonRight onClick={nextSlide} disabled={slideCount === currentSlide + slidesToShow}>
                                                    <Icon name="chevron right" size="22px" />
                                                </T.CarouselButtonRight>
                                            }

                                        </>
                                    )}
                                >
                                    {_.map(sortedLists.undoneStarted, (p, i) => {
                                        const isAAO = p.curNodes.length === 0;

                                        return (
                                            <PlaylistOverviewCarouselItem data={p} isAAO={isAAO} key={i} />
                                        );
                                    })}
                                </Carousel>
                            </T.CarouselContainer>
                        </>
                    }
                    <T.Headline>
                        {searchTerm === "" ? t("all playlists") : t('all playlists filtered by "{{search}}"', { search: searchTerm })}
                        <T.HeadlineInput>
                            <div style={{ marginRight: adminAndNotMobile ? 40 : 0 }}>
                                <Input width={250} value={searchTerm} autoComplete='off' icon='search' onChange={e => setSearchTerm(e.target.value)} style={{ fontSize: "1rem" }} type='search' kind='default' />
                            </div>
                            {searchTerm !== "" &&
                                <T.HeadlineInputClear onClick={() => setSearchTerm("")} style={{ right: adminAndNotMobile ? 50 : 10 }}>
                                    <Icon name='times' color='@lightGrey' marginTop={0} />
                                </T.HeadlineInputClear>
                            }
                            {adminAndNotMobile &&
                                <div style={{ position: "absolute", top: 0, right: 0, zIndex: 5 }}>
                                    <Button
                                        icon="playlist edit"
                                        kind="cancelButton"
                                        onClick={gotoNamedRouteMethod("playlist_manage")}
                                        tooltip={{ tooltipText: t("playlist edit") }}
                                    />
                                </div>
                            }
                        </T.HeadlineInput>
                    </T.Headline>
                    <T.TableGrid showButtons={showBtns} >
                        <T.TitleRowCell style={{ cursor: "default" }} />
                        <T.TitleRowCell style={{ padding: 0, cursor: "default" }} />
                        <T.TitleRowCell onClick={setSort("name")}>
                            <div>{t("name")}</div>
                            {sortBy === "name" ?
                                <div><Icon name={reverseSort ? "arrow down" : "arrow up"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                : <div />}
                        </T.TitleRowCell>
                        {showDescs ? <T.TitleRowCell style={{ cursor: "default" }}>
                            <div>{t("description")}</div>
                        </T.TitleRowCell> : <div />}
                        {isDesktop ? <T.TitleRowCell onClick={setSort("assigned")}>
                            <div>{t("assigned")}</div>
                            {sortBy === "assigned" ?
                                <div><Icon name={reverseSort ? "arrow down" : "arrow up"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                : <div />}
                        </T.TitleRowCell> : <div />}
                        {isTablet ? <T.TitleRowCell onClick={setSort("duedate")}>
                            <div>{t("due date")}</div>
                            {sortBy === "duedate" ?
                                <div><Icon name={reverseSort ? "arrow down" : "arrow up"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                : <div />}
                        </T.TitleRowCell> : <div />}
                        {isLaptop ? <T.TitleRowCell onClick={setSort("duration")}>
                            <div>{t("duration")}</div>
                            {sortBy === "duration" ?
                                <div><Icon name={reverseSort ? "arrow down" : "arrow up"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                : <div />}
                        </T.TitleRowCell> : <div />}
                        <T.TitleRowCell
                            onClick={setSort("progress")}
                        // style={{ cursor: "default" }}
                        >
                            <div>{t("progress")}</div>
                            {sortBy === "progress" ?
                                <div><Icon name={reverseSort ? "arrow down" : "arrow up"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                : <div />}
                        </T.TitleRowCell>
                        {showBtns && <T.TitleRowCell style={{ cursor: "default" }}>actions</T.TitleRowCell>}
                        <T.TitleRowCell style={{ cursor: "default" }} />


                        <T.HeadlineRow style={{ gridColumnEnd: showBtns ? 11 : 10 }} error={!hasMandatoryEntries}>
                            {hasMandatoryEntries ? t("mandatory") : t("no mandatory playlists found")}
                        </T.HeadlineRow>
                        {hasMandatoryEntries && _.map(sortedLists.undone, (p, i) => {
                            return (
                                <div className="rowWrapper" key={i}>
                                    <PlaylistOverviewItem
                                        data={p}
                                        key={p.id}
                                        showBtns={showBtns}
                                        progress={getProgressStatus(p.curNodes[0], p)}
                                    />

                                </div>
                            );
                        }
                        )}


                        {hasOptionalEntries &&
                            <>
                                <T.HeadlineRow style={{ gridColumnEnd: showBtns ? 11 : 10 }} error={!hasMandatoryEntries}>
                                    {hasMandatoryEntries ? t("optional") : t("no optional playlists found")}
                                </T.HeadlineRow>
                                {hasMandatoryEntries && _.map(sortedLists.optional, (p, i) => {
                                    return (
                                        <div className="rowWrapper" key={i}>
                                            <PlaylistOverviewItem data={p} key={p.id} showBtns={showBtns} progress='on Track' />
                                        </div>
                                    );
                                }
                                )}
                            </>
                        }
                        {sortedLists.done.length > 0 &&
                            <>
                                <T.HeadlineRow style={{ gridColumnEnd: showBtns ? 11 : 10 }} error={!hasMandatoryEntries}>
                                    {t("my completed playlists")}
                                </T.HeadlineRow>

                                {_.map(sortedLists.done, (p, i) => {
                                    return (
                                        <div className="rowWrapper" key={i}>
                                            <PlaylistOverviewItem data={p} key={p.id} showBtns={showBtns} progress="done" />
                                        </div>
                                    );
                                }
                                )}
                            </>
                        }
                    </T.TableGrid>

                </>
            }
            {admin &&
                <T.AdminPanel >
                    <div style={{ float: "left", lineHeight: "40px", paddingLeft: 20 }}>Temporary Admin Panel</div>
                    <div style={{ float: "right", borderLeft: "1px solid #e9e9e9", width: 1, height: "100%", marginRight: 10 }} />
                    <div style={{ float: "right", lineHeight: "20px", marginRight: 10, marginTop: 9, cursor: "pointer" }} onClick={() => setShowBtns(!showBtns)}>
                        Reset  Buttons
                    </div>
                    <div style={{ float: "right", lineHeight: "20px", marginRight: 10, marginTop: 7 }}>
                        <Checkbox label='hide' onClick={() => !showBtns ? undefined : setShowBtns(false)} selected={!showBtns} />
                    </div>
                    <div style={{ float: "right", lineHeight: "20px", marginRight: 10, marginTop: 7 }}>
                        <Checkbox label='show' onClick={() => showBtns ? undefined : setShowBtns(true)} selected={showBtns} />
                    </div>
                    <div style={{ float: "right", borderLeft: "1px solid #e9e9e9", width: 1, height: "100%", marginRight: 10 }} />
                </T.AdminPanel>
            }

        </div>
    );
}
export default PlaylistOverview;